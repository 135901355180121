import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function CheckoutSuccess() {
  const booking = useSelector(state => state.booking);
  const [isSuccess, setIsSuccess] = useState(null);
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [codeBooking, setCodeBooking] = useState(null);
  const product = useSelector(state => state.product);
  const tickets = product.ticket;

  useEffect(() => {
    let payload = {
      startFrom: moment(booking.details.from).format('YY-MM-DD'),
      endUntil: moment(booking.details.until).format('YY-MM-DD'),
      email: booking.details.email,
      name: booking.details.fullName,
      phone: booking.details.phone,
      participant: booking.details.participant,
      totalPrice: booking.details.totalPrice,
      package: booking.details.allListProductPrice,
      ticketPricePerPerson: tickets[0].price,
      amountFreeParticipant: booking.details.personFreeCharge
    };
    if (isSuccess === null) {
      axios
        .post('https://api.bukitasahbali.com/public/index.php/bookingcamping/confirmation', payload)
        .then(data => {
          setCodeBooking(data.data.bookingCamping.bookingCode);
          setIsSuccess(true);
        })
        .catch(err => {
          if (err.response) {
            let messageError = [
              <p key={1}>{err.response.data.errors.message}.</p>
            ];
            if (err.response.data.errors.remeaningPackage !== undefined) {
              messageError.push(<p key={2}>Paket yang masih tersisa:</p>);
              err.response.data.errors.remeaningPackage.forEach(e => {
                messageError.push(
                  <p key={e.package}>
                    {e.package} : {e.remeaningAmount} X
                  </p>
                );
              });
            }
            setMessageError(messageError);
          }
          setIsError(true);
        });
    }
  }, [
    booking.details.allListProductPrice,
    booking.details.email,
    booking.details.from,
    booking.details.fullName,
    booking.details.participant,
    booking.details.personFreeCharge,
    booking.details.phone,
    booking.details.totalPrice,
    booking.details.until,
    booking.idBooking,
    isSuccess,
    tickets
  ]);

  if (isError === true) {
    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          {/* Mohon Maaf Transaksi Anda Tidak Bisa Kami Proses, Mohon Melakukan
          Request Ulang. Terimakasih :) */}
          {messageError}
        </Typography>
      </React.Fragment>
    );
  }

  if (isSuccess === null) {
    return <Loader type="Circles" color="#00BFFF" height={100} width={100} />;
  }

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Thank you for your order.
      </Typography>
      <p>
        Kode booking Anda adalah{' '}
        <span style={{ fontWeight: 'bold' }}>{codeBooking}</span>.
      </p>
      <p>Untuk prosess selanjutnya silahkan transfer DP 50% ke no rek:</p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Bank Mandiri</span>
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>No rekening: 1450010426621</span>
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Atas nama: I Wayan Segara</span>
      </p>
      <p>
        Setelah melakukan transfer, konfirmasi pembayaran melalui WA ke no
        <span style={{ fontWeight: 'bold' }}> +6281937068702 </span> dengan
        menyertakan identitas yang Anda inputkan beserta kode booking
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>
          System akan otomatis membatalkan booking Anda jika tidak ada
          pembayaran dan konfirmasi dalam waktu kurang dari 24 jam.
        </span>
      </p>
      {/* <Typography variant="subtitle1">
        Kami akan segera menghubungi Anda untuk details lebih lanjut. Mohon
        dipastikan <span style={{ fontWeight: 'bold' }}>nomor telepon</span>{' '}
        atau <span style={{ fontWeight: 'bold' }}>email</span> yang Anda berikan
        aktif agar kami bisa menghubungi Anda.
      </Typography>
      <Typography variant="subtitle1"> </Typography>
      <Typography variant="subtitle1">
        Untuk mempercepat prosess, Anda bisa langshung click link whatsapp
        dibawah ini
      </Typography>
      <Typography variant="subtitle1">
        <a
          href={`https://wa.me/+6282359351889?text=Hi,%0aCodeBooking:%0a${codeBooking}
          %0aMohon segera diproses%0aTerimakasih`}
        >
          <strong>+6281937068702</strong>
        </a>
      </Typography> */}
    </React.Fragment>
  );
}

export default CheckoutSuccess;
