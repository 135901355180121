import axios from 'axios';

export const getTicketCamping = () => dispatch => {
  axios
    .get('https://api.bukitasahbali.com/public/index.php/bookingcamping/ticket')
    .then(data => {
      dispatch({
        type: 'SUCCESS_GET_TICKET_CAMPING',
        payload: {
          ticketCamping: data.data.ticketCamping
        }
      });
    })
    .catch(err => {
      alert('Error, please reload this page!');
    });
};

export const getTentPackage = () => dispatch => {
  axios
    .get('https://api.bukitasahbali.com/public/index.php/bookingcamping/package')
    .then(data => {
      dispatch({
        type: 'SUCCESS_GET_TENT_PACKAGE',
        payload: {
          tentPackage: data.data.tentPackage
        }
      });
    })
    .catch(err => {
      alert('Error, please reload this page!');
    });
};
