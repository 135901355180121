const initState = {
  language: 'indonesia'
};

const languageReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_LANGUAGE_SUCCESS':
      return {
        ...state,
        language: action.payload.language
      };
    default:
      return state;
  }
};

export default languageReducer;
