import { combineReducers } from 'redux';
import languageReducer from './languageReducer';
import bookingReducer from './bookingReducer';
import productReducer from './productReducer';

const rootReducer = combineReducers({
  language: languageReducer,
  booking: bookingReducer,
  product: productReducer
});

export default rootReducer;
