const initState = {
  ticket: [],
  bundle: [],
  alreadyFetchTicket: false,
  alreadyFetchBundle: false,
  location: [
    {
      value: 'R-1-1',
      label: 'R-1-1'
    },
    {
      value: 'C-1-1',
      label: 'C-1-1'
    },
    {
      value: 'L-1-1',
      label: 'L-1-1'
    }
  ]
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SUCCESS_GET_TICKET_CAMPING':
      return {
        ...state,
        alreadyFetchTicket: true,
        ticket: [action.payload.ticketCamping]
      };
    case 'SUCCESS_GET_TENT_PACKAGE':
      return {
        ...state,
        alreadyFetchBundle: true,
        bundle: action.payload.tentPackage
      };
    default:
      return state;
  }
};

export default productReducer;
