import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../../FormFields';

import text from '../../../text';
import { collectBookingDetails } from '../../../store/action/bookingAction';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

export default function IdentityForm(props) {
  const {
    formField: {
      fullName,
      email,
      phone,
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      zipcode,
      country,
      useAddressForPaymentDetails
    }
  } = props;
  const { values: formValues } = useFormikContext();
  const textForm = text.form.indonesia;
  const dispatch = useDispatch();
  dispatch(collectBookingDetails(formValues));
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {textForm.step1.title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <InputField name={fullName.name} label={fullName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={phone.name} label={phone.label} fullWidth />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
