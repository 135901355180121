const initState = {
  details: {}
};

const bookingReducer = (state = initState, action) => {
  switch (action.type) {
    case 'COLLECT_BOOKING_DETAILS':
      return {
        ...state,
        details: action.payload.details
      };
    default:
      return state;
  }
};

export default bookingReducer;
