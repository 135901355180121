import * as Yup from 'yup';
import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    fullName,
    email,
    phone,
    from,
    until,
    location,
    participant,
    item,
    firstName,
    lastName,
    address1,
    city,
    zipcode,
    country,
    nameOnCard,
    cardNumber,
    expiryDate,
    cvv
  }
} = checkoutFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const phoneRegEx = /^[0-9]+$/;
const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const participantRegEx = /^[0-9]+$/;
let fromDate = '';
let untilDate = '';

export default [
  Yup.object().shape({
    [fullName.name]: Yup.string().required(`${fullName.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email('Wrong email format')
      .required(`${email.requiredErrorMsg}`),
    // .matches(emailRegEx, email.invalidErrorMsg),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
      .matches(phoneRegEx, phone.invalidErrorMsg)
  }),
  Yup.object().shape({
    [from.name]: Yup.string()
      .required(`${from.requiredErrorMsg}`)
      .test('fromDate', from.invalidErrorMsg, val => {
        if (val) {
          const startDate = new Date();
          const endDate = new Date(2050, 12, 31);
          if (moment(val, moment.ISO_8601).isValid()) {
            fromDate = val;
            return true;
          }
          return false;
        }
        return false;
      }),
    [until.name]: Yup.string()
      .required(`${until.requiredErrorMsg}`)
      .test('untilDate', until.invalidErrorMsg, val => {
        if (val) {
          const startDate = new Date();
          const endDate = new Date(2050, 12, 31);
          if (moment(val, moment.ISO_8601).isValid()) {
            untilDate = val;
            if (fromDate > untilDate) {
              return false;
            }
            return true;
          }
          return false;
        }
        return false;
      }),
    // [location.name]: Yup.string().required(`${location.requiredErrorMsg}`),
    // [item.name]: Yup.string().required(`${item.requiredErrorMsg}`),
    [participant.name]: Yup.number()
      .min(1)
      .required(`${participant.requiredErrorMsg}`)
      .typeError('Wrong number format')
    // .matches(participantRegEx, participant.invalidErrorMsg)
    // [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
    // [cardNumber.name]: Yup.string()
    //   .required(`${cardNumber.requiredErrorMsg}`)
    //   .matches(visaRegEx, cardNumber.invalidErrorMsg),
    // [expiryDate.name]: Yup.string()
    //   .nullable()
    //   .required(`${expiryDate.requiredErrorMsg}`)
    //   .test('expDate', expiryDate.invalidErrorMsg, val => {
    //     if (val) {
    //       const startDate = new Date();
    //       const endDate = new Date(2050, 12, 31);
    //       if (moment(val, moment.ISO_8601).isValid()) {
    //         return moment(val).isBetween(startDate, endDate);
    //       }
    //       return false;
    //     }
    //     return false;
    //   }),
    // [cvv.name]: Yup.string()
    //   .required(`${cvv.requiredErrorMsg}`)
    //   .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
  })
];
