const text = {
  form: {
    english: {
      title: 'Camping Booking Form'
    },
    indonesia: {
      title: 'Camping Booking Form',
      step1: {
        title: 'Identitas'
      },
      step2: {
        title: 'Informasi Kemah Details'
      },
      step3: {
        title: 'Review Order'
      }
    }
  }
};

export default text;
