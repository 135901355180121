import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    fullName,
    email,
    phone,
    from,
    until,
    location,
    participant,
    item,
    amountItem
  }
} = checkoutFormModel;

export default {
  [fullName.name]: '',
  [email.name]: '',
  [phone.name]: '',
  [from.name]: '',
  [until.name]: '',
  [location.name]: '',
  [item.name]: '',
  [participant.name]: 1,
  [amountItem.name]: 1,
  packageItem: {}
  // [firstName.name]: '',
  // [lastName.name]: '',
  // [address1.name]: '',
  // [city.name]: '',
  // [zipcode.name]: '',
  // [country.name]: '',
  // [useAddressForPaymentDetails.name]: false,
  // [nameOnCard.name]: '',
  // [cardNumber.name]: '',
  // [expiryDate.name]: '',
  // [cvv.name]: ''
};
