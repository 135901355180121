import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, DatePickerField, SelectField } from '../../FormFields';
import useStyles from '../styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useFormikContext } from 'formik';
// import map_camping from '../../../map_camping.png';

import { useDispatch, useSelector } from 'react-redux';
import { collectBookingDetails } from '../../../store/action/bookingAction';

let packageItem = {};

export default function CampingDetailForm(props) {
  const classes = useStyles();
  const { values: formValues } = useFormikContext();
  const {
    formField: { from, until, participant }
  } = props;
  const dispatch = useDispatch();
  const product = useSelector(state => state.product);
  const [itemPackage, setItemPackage] = useState({});
  const myProducts = product.bundle;
  const tickets = product.ticket;

  formValues.packageItem = packageItem;
  dispatch(collectBookingDetails(formValues));

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Informasi Kemah
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Paket Tenda</TableCell>
                  <TableCell>Harga (IDR)</TableCell>
                  <TableCell>Fasilitas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myProducts.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.price
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </TableCell>
                    <TableCell>
                      {row.products.map(product => (
                        <li key={product.name}>
                          {product.amount} X. {product.name}
                        </li>
                      ))}
                      <li>
                        Biaya tambahan untuk perserta lebih dari{' '}
                        {row.personFreeCharge} orang. Rp{' '}
                        {tickets[0].price
                          .toFixed(2)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                        per orang
                      </li>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            name={from.name}
            label={from.label}
            format="dd-MM-yy"
            views={['year', 'month', 'date']}
            minDate={new Date()}
            maxDate={new Date('2050/12/31')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            name={until.name}
            label={until.label}
            format="dd-MM-yy"
            views={['year', 'month', 'date']}
            minDate={new Date()}
            maxDate={new Date('2050/12/31')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Jumlah Paket</TableCell>
                  <TableCell>Nama Paket</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myProducts.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <input
                        type="text"
                        value={packageItem[row.name] ?? ''}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            alert('Wrong input format, must be a number !');
                          } else {
                            packageItem = {
                              ...packageItem,
                              [row.name]: e.target.value
                            };
                            setItemPackage({
                              ...packageItem,
                              [row.name]: e.target.value
                            });
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* {packageItemForm} */}
        {/* <Grid item xs={12} md={6}>
          <SelectField
            name={item.name}
            label={item.label}
            data={myProducts}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={amountItem.name}
            label={amountItem.label}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={participant.name}
            label={participant.label}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>
            DENAH
          </Typography>
          <img src={map_camping} alt="Bukit Asah" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField
            name={location.name}
            label={location.label}
            data={locations}
            fullWidth
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
