import React from 'react';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function PaymentDetails(props) {
  const { booking } = props;
  const classes = useStyles();
  const { participant, from, until } = booking;
  return (
    <Grid item container direction="column" xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Camping Details
      </Typography>
      <Grid container>
        {/* <React.Fragment>
        <Grid item xs={6}>
          <Typography gutterBottom>Location</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{location.name}</Typography>
        </Grid>
      </React.Fragment> */}
        <React.Fragment>
          <Grid item xs={6}>
            <Typography gutterBottom>Peserta</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>{participant} orang</Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={6}>
            <Typography gutterBottom>Jangka Waktu</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>{booking.longDays} hari</Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={6}>
            <Typography gutterBottom>Check in</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              {moment(from).format('MMMM Do YYYY')}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={6}>
            <Typography gutterBottom>Check out</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              {moment(until).format('MMMM Do YYYY')}
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default PaymentDetails;
