export default {
  formId: 'checkoutForm',
  formField: {
    fullName: {
      name: 'fullName',
      label: 'Name*',
      requiredErrorMsg: 'Name is required'
    },
    email: {
      name: 'email',
      label: 'Email*',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'Email is not vaild (e.g. mail@gmail.com)'
    },
    phone: {
      name: 'phone',
      label: 'Phone*',
      requiredErrorMsg: 'Phone number is required',
      invalidErrorMsg: 'Phone number is not valid (e.g. 081338111111)'
    },
    from: {
      name: 'from',
      label: 'Check In*',
      requiredErrorMsg: 'Check In is required',
      invalidErrorMsg:
        'Check In date is not valid (Format: date-month-year. e.g. 31-12-20)',
      invalidErrorMsg2: 'Check Out must higher than Check In'
    },
    until: {
      name: 'until',
      label: 'Check Out*',
      requiredErrorMsg: 'Check Out is required',
      invalidErrorMsg:
        'Check Out date is not valid (e.g. 31-12-20, must higher than Check In)',
      invalidErrorMsg2: 'Check Out must higher than Check In'
    },
    location: {
      name: 'location',
      label: 'Location*',
      requiredErrorMsg: 'Location is required'
    },
    item: {
      name: 'item',
      label: 'Package*',
      requiredErrorMsg: 'Package is required'
    },
    amountItem: {
      name: 'amountItem',
      label: 'Jumlah Package*',
      requiredErrorMsg: 'Jumlah Package is required'
    },
    participant: {
      name: 'participant',
      label: 'Jumlah Peserta*',
      requiredErrorMsg: 'Peserta is required, minimal 1',
      invalidErrorMsg: 'Peserta is not valid (e.g. 1)'
    },
    firstName: {
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    address1: {
      name: 'address1',
      label: 'Address Line 1*',
      requiredErrorMsg: 'Address Line 1 is required'
    },
    address2: {
      name: 'address2',
      label: 'Address Line 2'
    },
    city: {
      name: 'city',
      label: 'City*',
      requiredErrorMsg: 'City is required'
    },
    state: {
      name: 'state',
      label: 'State/Province/Region'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zipcode*',
      requiredErrorMsg: 'Zipcode is required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)'
    },
    country: {
      name: 'country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Use this address for payment details'
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: 'Name on card*',
      requiredErrorMsg: 'Name on card is required'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Card number*',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    expiryDate: {
      name: 'expiryDate',
      label: 'Expiry date*',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: 'CVV is required',
      invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    }
  }
};
