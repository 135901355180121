import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import ProductDetails from './ProductDetails';
import ShippingDetails from './ShippingDetails';
import PaymentDetails from './PaymentDetails';

import { useDispatch, useSelector } from 'react-redux';
import { collectBookingDetails } from '../../../store/action/bookingAction';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  // const [isFinishCalculate, setIsFinishCalculate] = useState(false);
  const dispatch = useDispatch();
  const product = useSelector(state => state.product);
  // const booking = useSelector(state => state.booking);
  const myProducts = product.bundle;
  const tickets = product.ticket;

  const {
    item,
    participant,
    from,
    until,
    amountItem,
    packageItem
  } = formValues;
  let personFreeCharge = 0;
  let longDays = dateDiffInDays(new Date(from), new Date(until));
  if (parseInt(longDays) < 1) {
    longDays = 1;
  }

  let productPrice = 0;
  let allListProductPrice = [];
  for (var key in packageItem) {
    if (packageItem.hasOwnProperty(key)) {
      let amountOfPackage = parseInt(packageItem[key]);
      if (isNaN(amountOfPackage)) {
        amountOfPackage = 0;
      }
      // eslint-disable-next-line no-loop-func
      myProducts.find(t => {
        if (t.name === key || t.value === key) {
          personFreeCharge += t.personFreeCharge * amountOfPackage;
          productPrice += t.price * amountOfPackage;
          allListProductPrice.push({
            id: t.id,
            name: key,
            amount: amountOfPackage,
            totalPrice: t.price * amountOfPackage
          });
          return t.price;
        }
      });
    }
  }

  let ticketPrice =
    (parseInt(participant) - parseInt(personFreeCharge)) * tickets[0].price;
  if (ticketPrice < 0) {
    ticketPrice = 0;
  }

  const totalPrice =
    (parseInt(productPrice) + parseInt(ticketPrice)) * parseInt(longDays);

  const booking = {
    ...formValues,
    totalPrice: totalPrice,
    ticketPrice: ticketPrice,
    longDays: longDays,
    allListProductPrice: allListProductPrice,
    personFreeCharge: personFreeCharge
  };

  dispatch(collectBookingDetails(booking));
  // setIsFinishCalculate(true);
  // if (!isFinishCalculate) {
  //   return null;
  // }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <ProductDetails booking={booking} />
      <Grid container spacing={2}>
        <ShippingDetails booking={booking} />
        <PaymentDetails booking={booking} />
      </Grid>
    </React.Fragment>
  );
}
