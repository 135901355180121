import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import IdentityForm from './Forms/IdentityForm';
import CampingDetailForm from './Forms/CampingDetailForm';
import ReviewOrder from './ReviewOrder';
import CheckoutSuccess from './CheckoutSuccess';

import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';

import { SelectField } from '../FormFields';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTicketCamping,
  getTentPackage
} from '../../store/action/productAction';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// import ReactLanguageSelect from 'react-languages-select';

//import css module
// import 'react-languages-select/css/react-languages-select.css';

//OR import sass module
// import 'react-languages-select/scss/react-languages-select.scss';

import text from '../../text';

import useStyles from './styles';

const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <IdentityForm formField={formField} />;
    case 1:
      return <CampingDetailForm formField={formField} />;
    case 2:
      return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const textForm = text.form.indonesia;
  const product = useSelector(state => state.product);
  const dispatch = useDispatch();

  if (product.alreadyFetchTicket === false) {
    dispatch(getTicketCamping());
    return <Loader type="Circles" color="#00BFFF" height={100} width={100} />;
  }

  if (product.alreadyFetchBundle === false) {
    dispatch(getTentPackage());
    return <Loader type="Circles" color="#00BFFF" height={100} width={100} />;
  }

  const steps = [
    textForm.step1.title,
    textForm.step2.title,
    textForm.step3.title
  ];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      {/* <div className={classes.buttons}>
        <select className={classes.wrapper}>
          <option value="Indonesia">Indonesia</option>
          <option value="English">English</option>
        </select>
      </div> */}
      <Typography component="h1" variant="h4" align="center">
        {textForm.title}
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
