import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import useStyles from './styles';

const products = [
  { name: 'Product 1', desc: 'A nice thing', price: '$9.99' },
  { name: 'Product 2', desc: 'Another thing', price: '$3.45' },
  { name: 'Product 3', desc: 'Something else', price: '$6.51' },
  { name: 'Product 4', desc: 'Best thing of all', price: '$14.11' },
  { name: 'Shipping', desc: '', price: 'Free' }
];

function ProductDetails(props) {
  const classes = useStyles();
  const { booking } = props;
  const { allListProductPrice, personFreeCharge } = booking;
  let listPricePackage = [];
  allListProductPrice.forEach(alp => {
    listPricePackage.push(
      <ListItem className={classes.listItem} key={alp.name}>
        <ListItemText primary={alp.name} secondary={`(${alp.amount} X)`} />
        <Typography variant="body2">
          Rp{' '}
          {alp.totalPrice
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Typography>
      </ListItem>
    );
  });

  let participantString = `Total ${booking.participant} orang. Free ${personFreeCharge} orang`;
  let totalDays = `${booking.longDays} Hari`;

  // for (var key in packageItem) {
  //   if (packageItem.hasOwnProperty(key)) {
  //     // listPricePackage.push(<ListItemText
  //     //   primary={key}
  //     //   secondary={`(${packageItem[key]} X)`}
  //     // />
  //     // <Typography variant="body2">
  //     //   Rp{' '}
  //     //   {booking.productPrice
  //     //     .toFixed(2)
  //     //     .toString()
  //     //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
  //     // </Typography>)
  //   }
  // }

  return (
    <List disablePadding>
      {listPricePackage}
      <ListItem className={classes.listItem}>
        <ListItemText
          primary="Biaya sewa perorangan"
          secondary={participantString}
        />
        <Typography variant="body2">
          Rp{' '}
          {booking.ticketPrice
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Typography>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText primary="Total" secondary={totalDays} />
        <Typography variant="subtitle1" className={classes.total}>
          Rp{' '}
          {booking.totalPrice
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Typography>
      </ListItem>
    </List>
  );
}

export default ProductDetails;
